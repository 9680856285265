const statusTitle = [
  'pedding',
  'scheduled',
  'canceled',
  'interviewed',
  'not_interview',
  'service_started',
  'not_service_started',
  'expired',
];

export default statusTitle;
