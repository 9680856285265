import React from 'react';

import { Container } from './styles';

function Header() {
  return (
    <Container>
      <div>
        <h1>Administração</h1>
      </div>
    </Container>
  );
}

export default Header;
