const REGIONS = [
  'Cidade',
  'Ceilândia',
  'Samambaia',
  'Taguatinga',
  'Planaltina',
  'Guará',
  'Gama',
  'Recanto das Emas',
  'Santa Maria',
  'Águas Claras',
  'São Sebastião',
  'Riacho Fundo II',
  'Sol Nascente/Pôr do Sol',
  'Sobradinho II',
  'Sobradinho',
  'Vicente Pires',
  'Paranoá',
  'Itapoã',
  'Sudoeste/Octogonal',
  'Brazlândia',
  'Jardim Botânico',
  'Riacho Fundo',
  'Arniqueira',
  'Lago Norte',
  'SCIA',
  'Cruzeiro',
  'Lago Sul',
  'Núcleo Bandeirante',
  'Park Way',
  'Candangolândia',
  'Varjão',
  'Fercal',
  'SIA',
  'Asa sul',
  'Asa norte',
];

export default REGIONS;
