const servicesType = [
  'adultos',
  'idosos',
  'criancas',
  'adolescentes',
  'casal',
  'familias',
  'interverncoes',
];

export default servicesType;
